import http from "../axios/http"

//口袋推送列表接口
export let getList = (params) => {
    return http.get("/sms/kdpush_index", { params })
}
//微信推送列表接口
export let getListw = (params) => {
    return http.get("/sms/wxpush_index", { params })
}
//任务列表接口
export let getTaskList = (params) => {
    return http.get("/task/task_list", { params })
}
//通话号码记录详情（已做）
export let getRecord = (params) => {
    return http.get("/number/record", { params })
}